import React, { useEffect } from 'react';
export default () => {
  const id = 'NTiPl6a0jvCa2oaPRANx';

  const fields = [
    {
      type: 'text',
      name: 'name',
      placeholder: 'Name',
      width: 'is-half',
      rules: 'required',
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'Email',
      width: 'is-half',
      rules: 'email|required',
    },
    {
      type: 'text',
      name: 'phone',
      placeholder: 'Phone',
      width: 'is-half',
      rules: 'required',
    },
    {
      type: 'text',
      name: 'property',
      placeholder: 'Property Address',
      width: 'is-half',
      rules: 'required',
    },
  ];

  // This needs tidied to prevent the script getting loaded in more than once

  useEffect(() => {
    const kwesScript = document.createElement('script');
    kwesScript.setAttribute('src', 'https://kwesforms.com/js/kwes.js');
    document.head.appendChild(kwesScript);
  }, []);

  return (
    <div className="our-form-wrapper">
      <div
        className="kwes-form maintenance-form"
        style={{ paddingTop: '20px', paddingBottom: '20px' }}
      >
        <form
          className="form"
          method="POST"
          action={`https://kwesforms.com/api/foreign/forms/${id}`}
          encType="multipart/form-data"
          no-reload
          success-message="Thanks for sending us your maintenance request. We will get back to you as soon as possible."
        >
          <div className="columns is-multiline">
            {fields.map(f => (
              <div className={`column ${f.width} field`} key={f.name}>
                <div className="control">
                  <input
                    type={f.type}
                    name={f.name}
                    placeholder={f.placeholder}
                    className="input"
                    rules={f.rules ? f.rules : ''}
                  />
                </div>
              </div>
            ))}
            <div className="field column is-full">
              <div className="control">
                <textarea
                  name="description"
                  className="textarea"
                  placeholder="Description of Maintenance"
                  rules="required"
                />
              </div>
            </div>
            <div
              className="field column is-full"
              style={{
                paddingBottom: 0,
                marginBottom: 0,
                marginLeft: 'calc(0.75em - 1px)',
              }}
            >
              <p>attachments</p>
            </div>
            <div className="file column is-full" style={{ paddingTop: 0 }}>
              <fieldset className="kw-repeater" name="files">
                <input className="input" type="file" name="file" />
              </fieldset>
            </div>
            <div className="field column is-full">
              <div className="control">
                <button className="button" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
