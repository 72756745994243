import React from 'react';
import Layout from '../components/layout';
import Hero from '../components/hero'
import MaintenanceForm from '../components/forms/maintenance'
export default () => (
    <Layout>
        <Hero
            content={
                <h2 className="title" style={{ textTransform: 'uppercase' }}>
                    Maintenance.
          </h2>
            }
            additionalClasses="is-medium"
        />
        <section className="section content-section">
            <div className="container">
                <div className="columns" style={{ paddingBottom: '50px' }}>
                    <div className="column is-two-thirds">
                        <p>
                            Please contact us and attach a photo using the below form for any maintenance requirements and we will be in touch as soon as possible to arrange a contractor.
                            For any urgent requests please follow the steps as detailed below.
                    </p>
                        <MaintenanceForm />
                    </div>
                </div>
                <div className="columns ">
                    <div className="column  is-half">
                        <div className="column-title">
                            <hr />
                            <h3 className="is-size-6 title-with-hr is-uppercase">Urgent Maintenance</h3>
                        </div>
                        <div className="content-div">
                            <p style={{ marginLeft: '8px' }}>
                                If your maintenance is an emergency please log this online then call our offices on <a href="tel:+6434701122">03 470 1122</a>. If the request is outside of business hours please leave a voicemail on the office line. Additionally, please log a maintenance request via our online form – this will be picked up by a member of our team.
                    </p>
                            <p style={{ marginLeft: '8px' }}>
                                Non urgent maintenance: All other maintenance items are required to be logged online.
          </p></div>

                    </div>
                    <div className="column is-half">
                        <div className="column-title">
                            <hr />
                            <h3 className="is-size-6 title-with-hr is-uppercase">Lockouts</h3>
                        </div>
                        <div className="content-div">
                            <p>
                                During business hours (Monday - Friday 8:30am – 5:00pm) tenants will need to notify a member of our team to arrange key collection from the DPM offices.
                                All keys provided for lock outs are required to be returned to DPM within 2 hours of collection.
                                A deposit of $50.00 needs to be paid and will be refunded on return of the keys.
                            </p>
                            <p>
                                Outside of business hours all lock outs are charged. Please note - there is a call out fee for a property manager to arrange access out of hours.
                                If DPM staff are unavailable, tenants are advised to call Begg Security on <a href="+64800852344">0800 852 344</a>.
                            </p>






                        </div>

                    </div>
                </div>





            </div >
        </section>

    </Layout >
);
